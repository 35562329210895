<template>
  <div>
    <!--begin::Dashboard-->

    <div class="row">
      <div class="col-xl-12">
        <MixedWidget1></MixedWidget1>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xl-4">
        <ListWidget15></ListWidget15>
      </div>
      <div class="col-xl-4">
        <ListWidget16></ListWidget16>
      </div>
    </div>
    <div class="row">
      <template v-for="(promotion, i) in promotions">
        <div class="col-xl-6" v-bind:key="i">
          <Promotions :data="promotion"></Promotions>
        </div>
      </template>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";
import ListWidget16 from "@/view/content/widgets/list/Widget16.vue";
import Promotions from "@/view/content/widgets/list/Widget13.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "dashboard",
  components: {
    MixedWidget1,
    ListWidget14,
    ListWidget15,
    ListWidget16,
    Promotions,
  },
  data() {
    return {
      promotions: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getActivePromotions();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getActivePromotions() {
      ApiService.get("/promotions/active").then((response) => {
        this.promotions = response.data;
      });
    },
  },
};
</script>
