<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <div class="d-flex align-items-center pr-2 mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >{{ data.startDate }} - {{ data.endDate }}</span
          >
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="media/svg/misc/003-puzzle.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->
        <a
          href="#"
          class="text-dark font-weight-bolder text-hover-primary font-size-h4"
          >{{ data.name }}</a
        >
        <!--begin::Desc-->
        <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
          {{ data.description }}
        </p>
        <!--end::Desc-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget13",
  props: {
    data: {},
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
