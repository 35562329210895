<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
  >
    <!--begin::Body-->
    <div class="card-body">
      <router-link
        to="/prizes-catalog"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <a
          :href="href"
          class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
          @click="navigate"
          >Katalog Nagród</a
        >
      </router-link>
      <div class="font-weight-bold text-success mt-9 mb-5">
        <router-link
          to="/prizes-catalog"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate">ZOBACZ</a>
        </router-link>
      </div>
      <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
        Wybieraj nagrody z katalogu <br />zbieraj punkty
      </p>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget14"
};
</script>
