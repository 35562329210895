var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom bgi-no-repeat gutter-b card-stretch",staticStyle:{"background-position":"right top","background-size":"30% auto","background-image":"url(media/svg/shapes/abstract-2.svg)"}},[_c('div',{staticClass:"card-body"},[_c('router-link',{attrs:{"to":"/transaction-history"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"card-title font-weight-bold text-muted text-hover-primary font-size-h5",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Historia Transakcji")])]}}])}),_c('div',{staticClass:"font-weight-bold text-success mt-9 mb-5"},[_c('router-link',{attrs:{"to":"/transaction-history"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("ZOBACZ")])]}}])})],1),_vm._m(0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-dark-75 font-weight-bolder font-size-h5 m-0"},[_vm._v(" Twoja historia dokumentów "),_c('br'),_vm._v("płatności, rozliczenia ")])}]

export { render, staticRenderFns }